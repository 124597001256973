<template>
  <div class="vd_sidebar">
    <el-menu class="vd_sidebar-el-menu" :default-active="onRoutes" background-color="#ffffff" text-color="grey" active-text-color="grey" unique-opened router>
      <!--<el-menu-item index="/home">-->
      <!--  <i class="el-icon-s-home"></i>-->
      <!--  <span class="menuText" slot="title">Home</span>-->
      <!--</el-menu-item>-->
      <!--<el-menu-item index="/sample_list">-->
      <!--	<i class="el-icon-s-order"></i>-->
      <!--	<span class="menuText" slot="title">Samples</span>-->
      <!--</el-menu-item>-->
      <!--<el-submenu index="1">-->
      <!--  <template slot="title">-->
      <!--    <i class="el-icon-s-order"></i>-->
      <!--    <span class="menuText" slot="title">Samples</span>-->
      <!--  </template>-->
      <!--  <el-menu-item index="/sample_list">Classic</el-menu-item>-->
      <!--  <el-menu-item index="/chewer_list">Super Chewer</el-menu-item>-->
      <!--</el-submenu>-->
      <!--<el-menu-item index="/material_list">-->
      <!--  <i class="el-icon-s-goods"></i>-->
      <!--  <span class="menuText" slot="title">Materials</span>-->
      <!--</el-menu-item>-->
      <el-menu-item class="menuText" index="/SHBark/SalesContract">Sales Contract</el-menu-item>
      <el-menu-item class="menuText" index="/SHBark/Procurement">Procurement</el-menu-item>
      <el-menu-item class="menuText" index="/SHBark/Shipping">Shipping</el-menu-item>
      <el-menu-item class="menuText" index="/SHBark/WeeklyPOReport">Weekly PO Report</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      home: '/home'
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace('/', '');
    }
  },
  created() {}
};
</script>

<style scoped>
.vd_sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 75px;
  bottom: 0;
  overflow-y: scroll;
}
.vd_sidebar::-webkit-scrollbar {
  width: 0;
}
.vd_sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.vd_sidebar > ul {
  height: 100%;
}
.menuText {
  font-size: 16px;
}
</style>
