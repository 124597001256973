<template>
<div>
  <top-header></top-header>
  <side-menu></side-menu>
  <keep-alive>
      <router-view></router-view>
   </keep-alive>
	<transition name="fade">
		<div class="vd_refresh" v-show="flag" :style="['width:'+ flag +'px']">
			<el-card class="divFJA">
				<div class="vg_mb_8 vd_ca_f divFJA">Version Updated!</div>
				<div style="height: 20px"></div>
				<div class="divFJA"><el-button type="success" size="small" @click="refreshClick">Confirm</el-button></div>
			</el-card>
		</div>
	</transition>
</div>
</template>

<script>
import sideMenu from '@/components/SideMenu.vue'
import topHeader from '@/components/TopHeader.vue'
import {systemAPI} from "@api/modules/version";
import {get} from '@api/request';
import {userAPI} from "@api/modules/user";

export default {
  name: 'myMenu',
  components: {
    sideMenu,
    topHeader,
  },
	data() {
		return {
			flag:false,
			myInter:null,
			myInterTime:null,
			myInterNum:0,
			updateType:1
		}
	},
	mounted(){
		this.timer()
	},
	beforeDestroy(){
		clearInterval(this.myInter)
		this.myInter = null;
	},
	methods:{
		timer(){
			this.myInter = setInterval(()=>{
				this.getVersion()
			},300000)
		},
		getVersion(){
			get(systemAPI.getVersion, "")
					.then(res => {
						if (res.data.code === 0) {
							if (sessionStorage.getItem('loaded') !== res.data.data.version + '') {
								clearInterval(this.myInter)
								this.myInter = null;
								this.flag = true;
								this.updateType = res.data.data.update_type
								if (res.data.data.update_type === 2) {
									sessionStorage.setItem('loaded', res.data.data.version + '');
								}
							}
						} else {
							this.$message({message: res.data.msg, type: 'error'});
						}
					}).catch(() => {
			});
		},
		// 确认更新
		refreshClick(){
			this.flag = false;
			if(this.updateType === 1){
				get(userAPI.userLogout).then(res => {
					if (res.data.code === 0) {
						this.$cookies.remove('userInfo')
						this.$cookies.remove('push')
						this.$router.replace("/login")
					}
				})
			}else if(this.updateType === 2){
				window.location.reload();
			}
		}
	}
}

</script>
<style scoped>
.divFJA {
	display: flex;
	justify-content: center;
	align-items: center;
}
.vd_refresh{
	width: 300px;
	position: fixed;
	right: 10px;
	top: 15%;
	z-index: 999;
}
.vd_ca_f{
	font-size: 20px;
	color: rgb(255, 55, 0);
}
.vd_ca_t{
	display: flex;
	justify-content: flex-end;
}
</style>

